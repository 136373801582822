<template>
  <div class="doc">
    <Page>
      <!-- <template v-slot:breadcrumbs> breadcrumbs </template> -->
      <div class="doc-header">
        <h1 class="doc-title">Политика конфиденциальности</h1>
        <!-- <a href="javascript:void(0)" class="doc-header__link">
          Предыдущая версия документа доступна по ссылке
        </a> -->
      </div>
      <div class="doc-content">
        <p>
          1. Основные положения <br />
          1.1. Настоящее Положение о политике конфиденциальности (далее —
          Положение) является официальным документом ООО «ВИАРТУР», (далее —
          «Компания»). <br />
          1.2. Настоящее Положение устанавливает порядок обработки и защиты
          информации о физических и юридических лицах (далее — Пользователи),
          которые используют сервисы и формы обратной связи, расположенные на
          доменных именах ООО «ВИАРТУР» (далее — Сайт). <br />
          1.3. Целью Политики конфиденциальности является обеспечение защиты
          прав и свобод человека и гражданина при обработке его персональных
          данных. <br />
          1.4. Настоящее Положение распространяется на обработку персональных
          данных, полученных Компанией любыми средствами, включая Интернет, от
          неограниченного круга лиц, независимо от их места нахождения. <br />
          1.5. Положение о Политике конфиденциальности определяет порядок
          обработки персональных данных — действий (операций), совершаемых с
          использованием средств автоматизации или без использования таких
          средств с персональными данными, включая сбор, запись, систематизацию,
          накопление, хранение, уточнение (обновление, изменение), извлечение,
          использование, передачу (распространение, предоставление, доступ),
          обезличивание, блокирование, удаление и уничтожение персональных
          данных. <br />
          1.6. Отношения, связанные с обработкой персональных данных и
          информации о пользователях Сайта, регулируются настоящим Положением,
          официальными документами Компании и действующим законодательством РФ.
        </p>

        <p>
          2. Условия обработки персональных данных <br />
          2.1. Целью обработки персональных данных является выполнение
          обязательств Компании перед Пользователями в отношении использования
          Сайта и его сервисов. <br />
          2.2. Обработка персональных данных Пользователей осуществляется с
          согласия Пользователя на обработку его персональных данных.
          Использование Пользователем Сайта означает принятие Пользователем
          условий настоящего Положения. <br />
          2.3. Под персональными данными понимается любая информация, прямо или
          косвенно относящаяся к определенному физическому лицу (субъекту
          персональных данных) и которая может быть использована для
          идентификации определенного лица либо связи с ним. <br />
          2.4. Обработка персональных данных Пользователя осуществляется без
          ограничения срока, любым законным способом, в том числе в
          информационных системах персональных данных с использованием средств
          автоматизации или без использования таких средств.
        </p>

        <p>
          3. Сбор персональных данных
          <br />
          3.1. Компания может запрашивать персональные данные в любой момент,
          начиная с момента, когда субъект персональных данных связывается с
          Компанией. <br />
          3.2. Компания вправе использовать полученные персональные данные в
          соответствии с настоящей Политикой конфиденциальности. <br />
          3.3. Компания вправе запрашивать следующие персональные данные,
          включая, но не ограничиваясь: имя, фамилия, отчество субъекта
          персональных данных; номер телефона; адрес электронной почты. <br />
          3.4. Указанная в п.3.3. информация может быть объединена с иной
          информацией, полученной Компанией, с целью предоставления и/или
          улучшения программ оказания услуг, продуктов, информационного
          обслуживания и коммуникации.
        </p>

        <p>
          4. Хранение и использование персональных данных
          <br />
          4.1. Персональные данные Пользователей хранятся на электронных
          носителях, расположенных на территории Российской Федерации, и
          обрабатываются с использованием автоматизированных систем, за
          исключением случаев, когда неавтоматизированная обработка персональных
          данных необходима в связи с исполнением требований законодательства.
          После заключения договора с Пользователем персональные данные
          Пользователя также могут храниться на бумажных носителях. <br />
          4.2. Использование персональных данных Пользователей осуществляется в
          следующих формах: <br />
          4.2.1. Заключение и исполнение договоров; <br />
          4.2.2. Коммуникация с Пользователем в целях оказания услуг, выполнения
          программ, поставки продуктов Компании; <br />
          4.2.3. Направление уведомлений, содержащих информацию об изменениях
          положений, условий и политик, изменении тарифов, окончании срока
          действия договора, либо содержащих иную важную для Пользователя
          информацию, а также подтверждающих оплату услуг; <br />
          4.2.4. Использование персональных данных для внутренних целей
          Компании, таких как: проведение аудита, анализ данных и различных
          исследований в целях улучшения продуктов и услуг Компании, а также
          взаимодействие с Пользователями; <br />
          4.2.5. Иное использование персональных данных, соответствующее
          действующему законодательству и целям настоящего Положения.
        </p>

        <p>
          5. Передача персональных данных
          <br />
          5.1. Персональные данные Пользователей не передаются третьим лицам, за
          исключением случаев, предусмотренных действующим законодательством и
          настоящим Положением. <br />
          5.2. Пользователь дает свое согласие на передачу его персональных
          данных третьим лицам, если такая передача требуется для выполнения
          действующего договора, обязательств, вытекающих из договора, для
          оказания услуг/поставки продуктов Компании, либо для исполнения
          действующего законодательства, в том числе, но не ограничиваясь,
          следующим лицам: курьерские службы, организации почтовой связи,
          операторы электросвязи, банки и иные кредитные учреждения, посредники,
          оказывающие услуги оплаты, организации, осуществляющие взыскание
          задолженности (коллекторы), государственные органы, включая органы
          судебной власти, налоговые органы и федеральную службу судебных
          приставов и т.д. <br />
          5.3. Персональные данные Пользователя могут передаваться третьим
          лицам-контрагентам Компании с условием принятия такими контрагентами
          обязательств по обеспечению конфиденциальности полученной информации.
          <br />
          5.4. В случае реорганизации, ликвидации Компании, отчуждения бизнеса
          или уступки прав по иным основаниям Компания вправе передать любые
          персональные данные соответствующему третьему лицу. <br />
          5.5. Персональные данные Пользователя могут быть переданы по запросам
          уполномоченных органов государственной власти РФ только по основаниям
          и в порядке, установленным законодательством РФ. <br />
          5.6. В отдельных случаях Компания может предоставлять персональную
          информацию и данные стратегическим партнерам, которые работают с
          Компанией для предоставления продуктов и услуг, или лицам, которые
          помогают Компании реализовывать продукты и услуги потребителям. При
          этом персональные данные предоставляются Компанией только в целях
          обеспечения Пользователей продуктами и услугами, а также для улучшения
          этих продуктов и услуг и связанных с ними коммуникаций. <br />
          5.7. Компания вправе передавать персональные данные/информацию о
          Пользователе, если такое раскрытие необходимо или уместно в целях
          национальной безопасности, поддержания правопорядка или в иных
          общественно значимых случаях. <br />
          5.8. Персональные данные Пользователей могут быть переданы третьим
          лицам, если такое раскрытие необходимо для приведения в исполнение
          настоящего Положения либо для целей защиты деятельности Компании или
          других пользователей.
        </p>

        <p>
          6. Защита персональных данных
          <br />
          6.1. Компания предпринимает правовые, организационные,
          административные, технические и физические меры предосторожности для
          обеспечения защиты собранных персональных данных в соответствии со ст.
          19 Федерального закона от 27.07.2006 N152-ФЗ «О персональных данных» в
          целях обеспечения защиты персональных данных Пользователя от
          неправомерного или случайного доступа к ним, уничтожения, изменения,
          блокирования, копирования, распространения, а также от иных
          неправомерных действий третьих лиц. <br />
          6.2. Пользователи самостоятельно несут ответственность за выбор и
          достоверность персональных данных, которые они предоставляют Компании.
          При указании Пользователем своих персональных данных в публичных
          записях на Сайте Компании или иных местах, к которым осуществляется
          открытый доступ, Пользователь самостоятельно несет ответственность за
          опубликованные им персональные данные. <br />
          6.3. В случае изменения персональных данных, если такое изменение
          может повлиять на соблюдение настоящего Положения, на исполнение
          заключенных с Пользователем договоров и в иных случаях, когда
          изменение персональных данных является существенным для Компании и
          Пользователя, Пользователь должен предоставить информацию о таких
          изменениях Компании. В случае, если Пользователь не предоставил
          актуальные персональные данные Компании, Компания не несет
          ответственность за ненадлежащее исполнение своих обязательств перед
          Пользователем, если такое ненадлежащее исполнение обусловлено
          отсутствием у Компании актуальных персональных данных Пользователя.
        </p>

        <p>
          7. Информация, не являющаяся персональными данными
          <br />
          7.1. Компания может использовать идентификационные файлы cookies и
          иные технологии для анализа поведения пользователей, измерения
          эффективности рекламных акций и сетевых поисков. <br />
          7.2. Собранная таким образом информация не является персональными
          данными и используется для обеспечения более удобного взаимодействия
          Пользователя с Компанией и исключительно в маркетинговых целях. <br />
          7.3. Пользователь может отключить cookies в настройках используемого
          веб-браузера или мобильного устройства. В случае отключения
          Пользователем cookies некоторые функции Сайта могут стать
          недоступными.
        </p>

        <p>
          8. Ответственность сторон и разрешение споров
          <br />
          8.1. За каждое нарушение предусмотренных Политикой обязательств,
          Компания обязуется возместить другой стороне документально
          подтвержденный реальный ущерб в полном объеме, возникший в результате
          такого нарушения. <br />
          8.2. Споры и разногласия между Сторонами, связанные с применением
          и/или использованием настоящей Политики, Стороны будут пытаться
          разрешить путем переговоров. При не достижении согласия споры подлежат
          рассмотрению в суде, в порядке, предусмотренном действующим
          законодательством Российской Федерации. <br />
          8.3. При разрешении споров Стороны применяют нормы права Российской
          Федерации.
        </p>

        <p>
          9. Заключительные положения
          <br />
          9.1. Во всем остальном, что не предусмотрено в настоящем документе о
          Политике конфиденциальности, Компания обязуется руководствоваться
          нормами и положениями Федерального закона N152-ФЗ «О персональных
          данных» от 27.07.2006 г. <br />
          9.2. Посетитель Сайта Компании, предоставляющий свои персональные
          данные и информацию, тем самым соглашается с положениями настоящей
          Политики Конфиденциальности. <br />
          9.3. Компания вправе в любое время по своему усмотрению вносить любые
          изменения в настоящее Положение с целью дальнейшего совершенствования
          системы защиты от несанкционированного доступа к сообщаемым
          Пользователями персональным данным без согласия Пользователя. <br />
          9.4. Действие настоящего Положения не распространяется на действия
          интернет-ресурсов третьих лиц. <br />
          9.5. Настоящее Положение вступает в силу с момента его размещения на
          Сайте Компании.
        </p>
      </div>
    </Page>
  </div>
</template>

<script>
import Page from "@/components/base/Page.vue";

export default {
  name: "Doc",
  components: { Page },
};
</script>
